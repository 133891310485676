import { graphql } from "gatsby";
import { useIntl } from "../../hooks/useIntl";
import React from "react";
import { useCallback } from "react";
import Page from "../../components/Page";
import { getTranslations } from "../../helpers/translations";
import SEO from "../../components/SEO";
import { parseSEO } from "../../helpers/seo";
import {
  FoodServiceFormProps,
  ProductInquiriesForm,
  ProductInquiriesFormProps,
} from "../../components/ContactUs";
import { useStaticLabels } from "../../hooks/useStaticLabels";
import { isPublished } from "../../helpers/directus";

const ProductInquiries = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const getRootTranslatedData = useCallback(() => {
    return getTranslations(
      data,
      "directus.contact_us_page.translations",
      intl.locale
    );
  }, [data]);

  const getProductInquiriesData = useCallback(() => {
    return getTranslations(
      getRootTranslatedData(),
      "product_inquiries_form.translations",
      intl.locale
    );
  }, [getRootTranslatedData]);

  if (!isPublished(getRootTranslatedData()?.product_inquiries_status))
    return null;

  const getContactUsData = useCallback((): ProductInquiriesFormProps => {
    const t = getProductInquiriesData();

    return {
      backLabel: labels?.backToContactUs,
      productInquiries: {
        button: t?.button_label,
        cityPlaceholder: labels?.cityPlaceholder,
        statePlaceholder: labels?.stateProvincePlaceholder,
        countryPlaceholder: labels?.countryPlaceholder,
        emailPlaceholder: labels?.emailPlaceholder,
        klaviyoId: t?.klaviyo_id,
        lotCodePlaceholder: t?.lot_code_placeholder,
        orderNumberPlaceholder: t?.order_number_placeholder,
        subjectLinePlaceholder: t?.subject_line_placeholder,
        successMessage: t?.success_message,
        title: t?.title,
        yourMessagePlaceholder: t?.your_message_placeholder,
        firstNamePlaceholder: labels?.firstNamePlaceholder,
        lastNamePlaceholder: labels?.lastNamePlaceholder,
        storePlaceholder: t?.store,
        yourPhonePlaceholder: labels?.phonePlaceholder,
      },
    };
  }, [getProductInquiriesData, intl]);

  const getLabels = useCallback((): FoodServiceFormProps => {
    const t = getTranslations(
      data,
      "directus.contact_us_page.main_section.translations",
      intl.locale
    );
    return {
      backLabel: t?.back_to_contact_us_label,
    };
  }, [data, intl]);

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={
        <SEO
          {...parseSEO(
            getProductInquiriesData(),
            intl?.locale,
            null,
            "seo.translations"
          )}
        />
      }
    >
      <ProductInquiriesForm {...getLabels()} {...getContactUsData()} />
    </Page>
  );
};

export default ProductInquiries;

export const query = graphql`
  query ($locale: String) {
    directus {
      contact_us_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          languages_code {
            code
            name
          }
          status
          product_inquiries_status
          product_inquiries_form {
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              seo {
                ...SEO
              }
              id
              success_message
              languages_code {
                code
                name
              }
              title
              klaviyo_id
              lot_code_placeholder
              order_number_placeholder
              subject_line_placeholder
              your_message_placeholder
              button_label
              store
            }
          }
        }
      }
    }
  }
`;
